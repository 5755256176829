@import url('https://fonts.googleapis.com/css2?family=Borel&display=swap');

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.heading {
  display: flex;
  justify-content: center;
}

.heading-text{
  background: linear-gradient(to right, #ff0062,#ff2377, #ff3e88, #fc4b8f);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

