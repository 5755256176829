@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.contact {
    padding: 5%;
}

.mt-5 {
    margin-top: 20px;
}

input {
    height: 40px;
    border-color: #fff;
    border-style: solid;
    width: 100%;
    border-width: 1px;
    padding: 5px;
    margin: 0px 20px;
    box-shadow: 10px 10px 36px #d9d9d9,
        -10px -10px 36px #ffffff;
    font-family: "Ubuntu Mono", monospace;
    font-weight: 500;
    font-style: normal;
}

.name-email {
    display: flex;
    flex-direction: row;
    margin: 20px;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.message {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
}

textarea {
    height: 80px;
    border-color: #fff;
    margin: 20px;
    border-style: solid;
    width: 100%;
    border-width: 1px;
    padding: 13px;
    font-family: "Ubuntu Mono", monospace;
    font-weight: 500;
    font-style: normal;
    box-shadow: 10px 10px 36px #d9d9d9,
        -10px -10px 36px #ffffff;
}

textarea::placeholder,
input::placeholder {
    font-family: "Borel", cursive;
}

.form-container {
    margin: 0% 10%;
}


form {
    margin: 0% 5%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

button[type=submit] {
    margin: 20px 20px;
    background: linear-gradient(to right, #ff0062, #ff2377, #ff3e88, #fc4b8f);
    color: #fff;
    height: 40px;
    width: 200px;
    border-color: #ff0062;
    font-weight: bolder;
    font-size: 24px;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    box-shadow: 10px 10px 36px #d9d9d9, -10px -10px 36px #ffffff;
    justify-content: center;
    align-items: center;
}

.submitBtn {
    display: flex;
    justify-content: right;
    margin: 15px;
}

button:disabled,
button[disabled] {
    opacity: 70%;
}

@media screen and (max-width: 768px) {
    .name-email {
        flex-direction: column;
    }

    input, button[type=submit] {
        margin: 20px 0px;
    }

    textarea {
        padding: 5px;
        margin-top: 0px;
    }

    .message {
        flex-direction: column;
    }

    .form-container {
        margin: 0%;
    }
}