@import url('https://fonts.googleapis.com/css2?family=Borel&display=swap');

.about{
    padding: 5%;
}

.sign {
    font-family: "Borel", cursive;
    font-weight: 200;
    font-style: normal;
    line-height: 3;
}

a{
    color: #fc4b8f;
    text-decoration: none;
}

a:hover{
    text-decoration: underline;
}