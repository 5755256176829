.nopage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    font-family: "Borel", cursive;
}

.noPageContainer{
    display: flex;
    height: 62vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

button{
    background-color: #000;
    height: 40px;
    width: 200px;
    border-style: solid;
    color: #fff;
    border-color: #000;
    font-weight: bold;
    border-radius: 2px;
}


